var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CampaignHeader", {
        ref: "campaignHeader",
        attrs: { "campaign-id": _vm.campaignId }
      }),
      !_vm.loading
        ? _c(
            "v-expansion-panels",
            {
              staticClass: "mt-4",
              attrs: { multiple: "" },
              model: {
                value: _vm.expanded,
                callback: function($$v) {
                  _vm.expanded = $$v
                },
                expression: "expanded"
              }
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [
                    _vm._v("\n        Campaign History\n      ")
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("v-simple-table", {
                        staticClass: "mt-2",
                        attrs: { dense: "", "fixed-header": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c("thead", [
                                    _c(
                                      "tr",
                                      _vm._l(_vm.expandedHeaders, function(
                                        expandedHeader,
                                        index
                                      ) {
                                        return _c(
                                          "th",
                                          {
                                            key: index,
                                            staticClass: "text-left",
                                            attrs: {
                                              width: expandedHeader.width
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(expandedHeader.text) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.campaignLogs, function(log) {
                                      return _c(
                                        "tr",
                                        { key: log.rowKey },
                                        _vm._l(_vm.expandedHeaders, function(
                                          expandedHeader
                                        ) {
                                          return _c(
                                            "td",
                                            {
                                              key:
                                                log.partitionKey +
                                                "-" +
                                                expandedHeader.value,
                                              attrs: {
                                                width: expandedHeader.width
                                              }
                                            },
                                            [
                                              expandedHeader.value ===
                                              "createdOn"
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "displayDateTimeFormat"
                                                          )(
                                                            log[
                                                              expandedHeader
                                                                .value
                                                            ]
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          log[
                                                            expandedHeader.value
                                                          ]
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ])
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    }),
                                    0
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          437748403
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.mailingHistory, function(item) {
                return _c(
                  "v-expansion-panel",
                  { key: item.partitionKey },
                  [
                    _c("v-expansion-panel-header", [
                      _vm._v(
                        "\n        " +
                          _vm._s(item.mailingName) +
                          " History\n      "
                      )
                    ]),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c("h5", [_vm._v("Mailing History")]),
                        _c("v-simple-table", {
                          staticClass: "mt-2",
                          attrs: { dense: "", "fixed-header": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function() {
                                  return [
                                    _c("thead", [
                                      _c(
                                        "tr",
                                        _vm._l(_vm.expandedHeaders, function(
                                          expandedHeader,
                                          index
                                        ) {
                                          return _c(
                                            "th",
                                            {
                                              key: index,
                                              staticClass: "text-left",
                                              attrs: {
                                                width: expandedHeader.width
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(expandedHeader.text) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(item.mailingLogs, function(log) {
                                        return _c(
                                          "tr",
                                          { key: log.rowKey },
                                          _vm._l(_vm.expandedHeaders, function(
                                            expandedHeader
                                          ) {
                                            return _c(
                                              "td",
                                              {
                                                key:
                                                  log.partitionKey +
                                                  "-" +
                                                  expandedHeader.value,
                                                attrs: {
                                                  width: expandedHeader.width
                                                }
                                              },
                                              [
                                                expandedHeader.value ===
                                                "createdOn"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "displayDateTimeFormat"
                                                            )(
                                                              log[
                                                                expandedHeader
                                                                  .value
                                                              ]
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            log[
                                                              expandedHeader
                                                                .value
                                                            ]
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ])
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("h5", { staticClass: "mt-2" }, [
                          _vm._v("\n          Split History\n        ")
                        ]),
                        _c("v-simple-table", {
                          staticClass: "mt-2",
                          attrs: { dense: "", "fixed-header": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function() {
                                  return [
                                    _c("thead", [
                                      _c(
                                        "tr",
                                        _vm._l(_vm.expandedHeaders, function(
                                          expandedHeader,
                                          index
                                        ) {
                                          return _c(
                                            "th",
                                            {
                                              key: index,
                                              staticClass: "text-left",
                                              attrs: {
                                                width: expandedHeader.width
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(expandedHeader.text) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(item.splitLogs, function(log) {
                                        return _c(
                                          "tr",
                                          { key: log.rowKey },
                                          _vm._l(_vm.expandedHeaders, function(
                                            expandedHeader
                                          ) {
                                            return _c(
                                              "td",
                                              {
                                                key:
                                                  log.partitionKey +
                                                  "-" +
                                                  expandedHeader.value,
                                                attrs: {
                                                  width: expandedHeader.width
                                                }
                                              },
                                              [
                                                expandedHeader.value ===
                                                "createdOn"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "displayDateTimeFormat"
                                                            )(
                                                              log[
                                                                expandedHeader
                                                                  .value
                                                              ]
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            log[
                                                              expandedHeader
                                                                .value
                                                            ]
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ])
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        : _c("v-skeleton-loader", {
            staticClass: "mt-4",
            attrs: { type: "table" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
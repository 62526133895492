<template>
  <div>
    <CampaignHeader
      ref="campaignHeader"
      :campaign-id="campaignId"
    />

    <v-expansion-panels
      v-if="!loading"
      v-model="expanded"
      multiple
      class="mt-4"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          Campaign History
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table
            dense
            fixed-header
            class="mt-2"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(expandedHeader, index) in expandedHeaders"
                    :key="index"
                    class="text-left"
                    :width="expandedHeader.width"
                  >
                    {{ expandedHeader.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(log) in campaignLogs"
                  :key="log.rowKey"
                >
                  <td
                    v-for="(expandedHeader) in expandedHeaders"
                    :key="`${log.partitionKey}-${expandedHeader.value}`"
                    :width="expandedHeader.width"
                  >
                    <span v-if="expandedHeader.value === 'createdOn'">
                      {{ log[expandedHeader.value] | displayDateTimeFormat }}
                    </span>
                    <span v-else>
                      {{ log[expandedHeader.value] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-for="(item) in mailingHistory"
        :key="item.partitionKey"
      >
        <v-expansion-panel-header>
          {{ item.mailingName }} History
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h5>Mailing History</h5>
          <v-simple-table
            dense
            fixed-header
            class="mt-2"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(expandedHeader, index) in expandedHeaders"
                    :key="index"
                    class="text-left"
                    :width="expandedHeader.width"
                  >
                    {{ expandedHeader.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(log) in item.mailingLogs"
                  :key="log.rowKey"
                >
                  <td
                    v-for="(expandedHeader) in expandedHeaders"
                    :key="`${log.partitionKey}-${expandedHeader.value}`"
                    :width="expandedHeader.width"
                  >
                    <span v-if="expandedHeader.value === 'createdOn'">
                      {{ log[expandedHeader.value] | displayDateTimeFormat }}
                    </span>
                    <span v-else>
                      {{ log[expandedHeader.value] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <h5 class="mt-2">
            Split History
          </h5>
          <v-simple-table
            dense
            fixed-header
            class="mt-2"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(expandedHeader, index) in expandedHeaders"
                    :key="index"
                    class="text-left"
                    :width="expandedHeader.width"
                  >
                    {{ expandedHeader.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(log) in item.splitLogs"
                  :key="log.rowKey"
                >
                  <td
                    v-for="(expandedHeader) in expandedHeaders"
                    :key="`${log.partitionKey}-${expandedHeader.value}`"
                    :width="expandedHeader.width"
                  >
                    <span v-if="expandedHeader.value === 'createdOn'">
                      {{ log[expandedHeader.value] | displayDateTimeFormat }}
                    </span>
                    <span v-else>
                      {{ log[expandedHeader.value] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-skeleton-loader
      v-else
      type="table"
      class="mt-4"
    />
  </div>
</template>

<script>
import { mailingService } from '@/shared/services'
import moment from 'moment'

export default {
  name: 'CampaignHistory',

  components: {
    CampaignHeader: () => import('@/views/pages/components/mailing/CampaignHeader')
  },

  props: {
    campaignId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      loading: false,
      campaignName: null,
      expanded: [...Array(6).keys()],
      campaignLogs: [],
      mailingHistory: [],
      expandedHeaders: [
        {
          text: 'Created By',
          value: 'createdByName',
          width: '200'
        },
        {
          text: 'Date',
          value: 'createdOn',
          width: '200'
        },
        {
          text: 'Topic',
          value: 'topic',
          width: '500'
        },
        {
          text: 'Details',
          value: 'topicDetails',
          width: '1000'
        }
      ]
    }
  },

  created () {
    this.loadCampaignHistory()
  },

  methods: {
    dateLocal (utc) {
      if (!utc) return null
      return moment(this.$_global_parseAsUtc(utc)).local().format()
    },
    async loadCampaignHistory () {
      this.loading = true
      var resp = await mailingService.getCampaignHistory(this.campaignId)
      this.campaignLogs = resp.campaignLogs.map(x => (
        {
          ...x,
          createdOn: this.dateLocal(x.createdOn)
        }
      ))
      this.campaignName = resp.campaignName
      resp.mailingHistory.forEach((item, index) => {
        var mailings = item.mailingLogs.map(x => (
          {
            ...x,
            createdOn: this.dateLocal(x.createdOn)
          }
        ))
        var splits = item.splitLogs.map(x => (
          {
            ...x,
            createdOn: this.dateLocal(x.createdOn)
          }
        ))
        this.mailingHistory.push({
          mailingName: item.mailingName,
          sequence: item.sequence,
          mailingLogs: mailings,
          splitLogs: splits
        })
      })
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
